import type { VirtualMachineStorage, VmStatusEnum } from "@warrenio/api-spec/spec.oats.gen";
import { notNull } from "@warrenio/utils/notNull";
import { useStandardMutation } from "../../modules/api/useStandardMutation.ts";
import { BaseDiskModal } from "../../modules/compute/DiskAddOrResizeModal.tsx";
import type { VmCommonFields } from "../../modules/compute/ModalHeaderBlock.tsx";
import type { Vm } from "../graphql.gen/graphql.ts";
import type { GQVmItem } from "./VmsTable.tsx";
import { resizeDiskMutation } from "./vmQuery.ts";

export function AdminDiskResizeModal({ vm, disk }: { vm: GQVmItem; disk: VirtualMachineStorage }) {
    //#region Hooks
    const resizeMutation = useStandardMutation(resizeDiskMutation);
    //#endregion

    return (
        <BaseDiskModal
            vm={gqlVmToCommonVm(vm)}
            disk={disk}
            onResizeDisk={async (params) => {
                await resizeMutation.mutateAsync(params);
            }}
        />
    );
}

function gqlVmToCommonVm(vm: GQVmItem): VmCommonFields {
    return {
        name: notNull(vm.name),
        location: notNull(vm._location),
        designated_pool_uuid: notNull(vm.designated_pool_uuid),
        os_name: notNull(vm.os_name),
        os_version: notNull(vm.os_version),
        uuid: vm.uuid,
        storage: notNull(vm.storage).map((s) => gqlStorageToVmStorage(s)),
        status: vm.status as VmStatusEnum,
        vcpu: vm.vcpu,
        memory: vm.memory,
    };
}

function gqlStorageToVmStorage(storage: NonNullable<Vm["storage"]>[number]): VirtualMachineStorage {
    return {
        size: notNull(storage.size),
        type: notNull(storage.type) as VirtualMachineStorage["type"],
        uuid: storage.uuid,
        created_at: storage.created_at as string,
        pool: storage.pool,
        name: storage.name,
        primary: notNull(storage.primary),
        shared: storage.shared,
        user_id: storage.user_id,
    };
}
