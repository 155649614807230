import type { VmIftuneBody } from "@warrenio/api-spec/spec.oats.gen";
import { notNull } from "@warrenio/utils/notNull";
import { useState } from "react";
import { WCheckbox } from "../../components/forms/WCheckbox.tsx";
import { WTextField } from "../../components/forms/WTextField.tsx";
import { WModalContent } from "../../components/modal/WModal.tsx";
import { useStandardMutation } from "../../modules/api/useStandardMutation.ts";
import { useSuspenseQueryAtom } from "../../utils/query/useSuspenseQueryAtom.ts";
import { AdminModalButton } from "../AdminModalButton.tsx";
import { Extra, Missing } from "../AdminTable.tsx";
import { AInput } from "../form/Fields.tsx";
import type { NicIftune } from "../graphql.gen/graphql.ts";
import { iftuneQueryAtom } from "./iftuneQuery.ts";
import { updateNicIftuneMutation } from "./vmQuery.ts";
import type { GQVmItem } from "./VmsTable.tsx";

export function NicIftuneBlock({ value: { average_kbps, burst_kb, peak_kbps } }: { value: NicIftune }) {
    const isNonZero = !!average_kbps || !!peak_kbps || !!burst_kb;

    if (!isNonZero) {
        return <span>Unlimited</span>;
    }
    return (
        <Extra className="whitespace-nowrap text-left" value={<>Average: {average_kbps} kB/s</>}>
            Peak: {peak_kbps} kB/s, Burst: {burst_kb} kB/s
        </Extra>
    );
}

export function NicIftuneButton({ item }: { item: GQVmItem }) {
    return (
        <AdminModalButton label={item.nic_iftune ? <NicIftuneBlock value={item.nic_iftune} /> : <Missing />}>
            <NicIftuneModalContent item={item} />
        </AdminModalButton>
    );
}

function NicIftuneModalContent({ item }: { item: GQVmItem }) {
    const vmConf = useSuspenseQueryAtom(iftuneQueryAtom);
    const modifyMutation = useStandardMutation(updateNicIftuneMutation);

    const isGlobal = !item.nic_iftune;
    const defaultConf = isGlobal ? vmConf : item.nic_iftune;

    const [global, setGlobal] = useState<boolean>(!isGlobal);
    const [unlimited, setUnlimited] = useState<boolean>(!!(item.nic_iftune && item.nic_iftune?.average_kbps === 0));
    const [average, setAverage] = useState<string | number>(defaultConf?.average_kbps ?? "");
    const [peak, setPeak] = useState<string | number>(defaultConf?.peak_kbps ?? "");
    const [burst, setBurst] = useState<string | number>(defaultConf?.burst_kb ?? "");

    async function onSubmit() {
        const confBody: VmIftuneBody = !global
            ? { uuid: item.uuid }
            : unlimited
              ? { uuid: item.uuid, average_kbps: 0, peak_kbps: 0, burst_kb: 0 }
              : {
                    uuid: item.uuid,
                    average_kbps: average ? Number(average) : 0,
                    peak_kbps: peak ? Number(peak) : 0,
                    burst_kb: burst ? Number(burst) : 0,
                };

        await modifyMutation.mutateAsync({
            body: confBody,
            location: notNull(item._location),
        });
    }

    function toggleGlobal(value: boolean) {
        if (!value) setUnlimited(false);
        setGlobal(value);
    }

    return (
        <WModalContent title="Bandwidth Limitations" label="Save" autoFocus modalAction={async () => await onSubmit()}>
            <p className="pb-4">Here you can adjust the bandwidth limitations for a specific Virtual Machine.</p>

            <div className="pb-3">
                <WCheckbox isSelected={global} onChange={toggleGlobal}>
                    Override global limitations
                </WCheckbox>
            </div>

            <div className="pb-1">
                <WCheckbox isReadOnly={!global} isSelected={unlimited} onChange={setUnlimited}>
                    Unlimited (value 0 equals to unlimited)
                </WCheckbox>
            </div>

            <WTextField
                isReadOnly={unlimited || !global}
                label="Average (kB/s)"
                onChange={setAverage}
                description="The desired average bit rate for VM network interface."
            >
                <AInput value={average} />
            </WTextField>

            <WTextField
                isReadOnly={unlimited || !global}
                label="Peak (kB/s):"
                onChange={setPeak}
                description="Defines the maximum speed achievable during a burst."
            >
                <AInput value={peak} />
            </WTextField>

            <WTextField
                isReadOnly={unlimited || !global}
                label="Burst (kB):"
                onChange={setBurst}
                description="Determines how much data can be transferred at a higher speed before reverting to the average speed."
            >
                <AInput value={burst} />
            </WTextField>
        </WModalContent>
    );
}
