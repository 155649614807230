import { useQuery } from "@apollo/client";
import { apiUnixToDate } from "@warrenio/api-spec/conversion";
import type { VmStatusEnum } from "@warrenio/api-spec/spec.oats.gen";
import { notNull } from "@warrenio/utils/notNull";
import { keys } from "remeda";
import { showModal } from "../../components/modal/registerModal.tsx";
import { OngoingBalance } from "../../modules/billing/OngoingBalance.tsx";
import type { SelectedOs } from "../../modules/compute/os/os.ts";
import { statusLabels } from "../../modules/compute/statusLabels.tsx";
import { VmStatusBadge } from "../../modules/compute/VmStatusBadge.tsx";
import { BillingAccountBlock } from "../accounts/BillingAccountBlock.tsx";
import { ActionButtons, type ActionItem } from "../ActionItem.tsx";
import { gf, type GqlFieldsOf } from "../FieldConfig.tsx";
import { EnumComponentFilter, NumberRangeFilter, TextFilter, UnixDateRangeFilter } from "../filters.tsx";
import { unassignAction } from "../floating_ips/unassignAction.tsx";
import { gql } from "../graphql.gen/gql.ts";
import { OrderDirection, VmOrderFields, type GetVmListQuery } from "../graphql.gen/graphql.ts";
import { extractData } from "../graphql/extractData.tsx";
import { GraphqlTable } from "../GraphqlTable.tsx";
import { useCursorPager } from "../Pager.tsx";
import { baIdField, currencyField, locationField, numberField, userIdField } from "../table_fields/standardFields.tsx";
import { DeleteVmBlockModalContent } from "./DeleteVmBlock.tsx";
import { MigrateVmBlockModalContent } from "./MigrateVmBlock.tsx";
import { NicIftuneButton } from "./NicIftuneBlock.tsx";
import { StartVmBlockModalContent } from "./StartVmBlock.tsx";
import { StopVmBlockModalContent } from "./StopVmBlock.tsx";
import { VmDetailView } from "./VmDetailView.tsx";
import { VmOs } from "./VmOs.tsx";
import { VmToolbar } from "./VmToolbar.tsx";

const GET_VMS = gql(/* GraphQL */ `
    query GetVmList(
        $limit: Int
        $orderField: VmOrderFields
        $orderDir: OrderDirection
        $cursor: String
        $page: Int
        $locations: [String!]
        $search: String
        $filters: [VmFilter!]
    ) {
        admin_vm_list(
            limit: $limit
            orderField: $orderField
            orderDir: $orderDir
            cursor: $cursor
            page: $page
            locations: $locations
            search: $search
            filters: $filters
        ) {
            items {
                uuid
                service_uuid
                hypervisor_uuid
                # hypervisor_name
                username
                updated_at

                is_designated_pool_visible
                designated_pool_name
                designated_pool_id
                designated_pool_uuid

                is_current_pool_visible
                current_pool_name

                billing_account
                billing_account_object {
                    ...BillingAccountBlock
                    running_totals {
                        ongoing
                    }
                }

                name
                description
                memory
                vcpu
                nic_iftune {
                    average_kbps
                    peak_kbps
                    burst_kb
                }
                status
                mac
                public_ipv6
                private_ipv4
                ip_address_object {
                    address
                    _location
                }
                os_name
                os_version
                user_id
                created_at
                deleted_at
                _location
                storage {
                    created_at
                    name
                    pool
                    primary
                    shared
                    size
                    type
                    user_id
                    uuid
                }
            }
            paging {
                total
                cursor
            }
        }
    }
`);

export type GQVmItem = NonNullable<GetVmListQuery["admin_vm_list"]["items"]>[number];

const vmFields: GqlFieldsOf<GQVmItem, VmOrderFields> = [
    gf({
        id: "uuid",
        title: "UUID",
        get: (a) => a.uuid,
        copyable: true,
        order: VmOrderFields.Uuid,
        filter: TextFilter,
    }),
    gf({
        ...userIdField,
        get: (a) => a.user_id,
        order: VmOrderFields.UserId,
    }),

    gf({
        ...baIdField,
        get: (a) => a.billing_account,
        order: VmOrderFields.BillingAccount,
    }),
    gf({
        id: "billing_account",
        title: "Billing Account",
        get: (a) => a.billing_account_object,
        render: BillingAccountBlock,
    }),
    gf({
        id: "service_uuid",
        title: "Service UUID",
        get: (a) => a.service_uuid,
        copyable: true,
        order: VmOrderFields.ServiceUuid,
        filter: TextFilter,
        hidden: true,
    }),
    gf({
        id: "hypervisor_uuid",
        title: "Host UUID",
        get: (a) => a.hypervisor_uuid,
        copyable: true,
        order: VmOrderFields.HostUuid,
        // XXX: Filtering/sorting by this field is broken for some reason
        // order: VmOrderFields.HypervisorUuid,
        filter: TextFilter,
        hidden: true,
    }),
    gf({
        ...currencyField,
        id: "ongoing_balance",
        title: "Ongoing\nBalance",
        get: (a) => a.billing_account_object?.running_totals?.ongoing ?? 0,
        render: OngoingBalance,
    }),
    gf({
        id: "created",
        title: "Created At",
        get: (a) => (a.created_at ? apiUnixToDate(a.created_at as number) : undefined),
        order: VmOrderFields.CreatedAt,
        filter: UnixDateRangeFilter,
    }),
    gf({
        id: "status",
        title: "Status",
        get: (a) => a.status as VmStatusEnum,
        order: VmOrderFields.Status,
        render: VmStatusBadge,
        filter: () => <EnumComponentFilter values={keys(statusLabels)} component={VmStatusBadge} />,
    }),
    gf({
        id: "os_name",
        title: "OS",
        get: (a): SelectedOs => ({ os_name: a.os_name!, os_version: a.os_version! }),
        render: VmOs,
    }),
    gf({
        id: "name",
        title: "VM Name",
        get: (a) => a.name,
        order: VmOrderFields.DisplayName,
        filter: TextFilter,
    }),
    gf({
        id: "public_ipv6",
        title: "Public IPv6",
        get: (a) => a.public_ipv6,
        copyable: true,
        order: VmOrderFields.PublicIpv6,
        filter: TextFilter,
    }),
    gf({
        id: "address",
        title: "Public IPv4",
        get: (a) => a.ip_address_object?.address,
        copyable: true,
        filter: TextFilter,
    }),
    gf({
        id: "private_ipv4",
        title: "Private IPv4",
        get: (a) => a.private_ipv4,
        copyable: true,
        order: VmOrderFields.PrivateIpv4,
        filter: TextFilter,
    }),
    gf({
        id: "mac",
        title: "MAC Address",
        get: (a) => a.mac,
        copyable: true,
        order: VmOrderFields.Mac,
        filter: TextFilter,
    }),
    gf({
        id: "limitations",
        title: "Bandwidth\nlimitations",
        get: (a) => a,
        render: NicIftuneButton,
    }),

    gf({
        ...numberField,
        id: "vcpu",
        title: "CPUs",
        get: (a) => a.vcpu,
        order: VmOrderFields.Vcpu,
        filter: NumberRangeFilter,
    }),
    gf({
        ...numberField,
        id: "memory",
        title: "RAM",
        get: (a) => a.memory,
        order: VmOrderFields.Memory,
        filter: NumberRangeFilter,
    }),
    gf({
        ...numberField,
        id: "disk",
        title: "Boot\nDisk",
        get: (a) => a.storage?.[0]?.size,
    }),
    gf({
        id: "username",
        title: "Username",
        get: (a) => a.username,
        order: VmOrderFields.Username,
        filter: TextFilter,
    }),
    gf({
        ...locationField,
        get: (a) => a._location,
    }),
];

function vmActions(item: GQVmItem): ActionItem[] {
    return [
        {
            id: "start_vm",
            title: "Start",
            icon: "jp-icon-run",
            isDisabled: item.status === "running",
            action: () => showModal(<StartVmBlockModalContent item={item} />),
        },
        {
            id: "stop_vm",
            title: "Stop",
            icon: "jp-icon-stop",
            isDisabled: item.status === "stopped",
            action: () => showModal(<StopVmBlockModalContent item={item} />),
        },
        {
            id: "force_stop_vm",
            title: "Force Stop",
            icon: "jp-icon-stop",
            isDisabled: item.status === "stopped",
            action: () => showModal(<StopVmBlockModalContent item={item} force />),
        },
        {
            id: "migrate_vm",
            title: "Migrate",
            icon: "jp-export-icon",
            action: () => showModal(<MigrateVmBlockModalContent item={item} />),
        },
        unassignAction(
            item.ip_address_object
                ? { address: item.ip_address_object.address, location: notNull(item._location) }
                : null,
        ),
        {
            id: "delete_vm",
            title: "Delete",
            icon: "jp-trash-icon",
            action: () => showModal(<DeleteVmBlockModalContent item={item} />),
        },
    ];
}

export function VmsTable() {
    return (
        <GraphqlTable
            title="Virtual Machines"
            fields={vmFields}
            defaults={{ orderField: VmOrderFields.CreatedAt, orderDir: OrderDirection.Desc }}
            getId={(item) => item.uuid}
            usePager={useCursorPager}
            useQuery={(variables) => {
                // eslint-disable-next-line react-hooks/rules-of-hooks
                const q = useQuery(GET_VMS, { variables });
                return extractData(q, (d) => d.admin_vm_list);
            }}
            actionButtons={(item) => <ActionButtons actions={vmActions(item)} inTable />}
            renderDetailToolbar={(item) => <VmToolbar item={item} />}
            renderDetail={(item) => <VmDetailView key={item.uuid} item={item} />}
        />
    );
}
